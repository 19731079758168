import React from "react"

export const Trainingszeiten = () => (
  <table className="mt-4 table">
    <thead className="thead">
      <tr>
        <th scope="col" className="th">
          Team
        </th>
        <th scope="col" className="th">
          Wochentag
        </th>
        <th scope="col" className="th">
          Uhrzeit
        </th>
        <th scope="col" className="th">
          Ort
        </th>
      </tr>
    </thead>
    <tbody className="divide-y divide-gray-700 dark:divide-gray-600">
      <tr>
        <td className="td">1. Herren</td>
        <td className="td">Dienstags</td>
        <td className="td">20:00 Uhr - 22:00 Uhr</td>
        <td className="td">Alter Bierweg 12, 38446 Wolfsburg</td>
      </tr>
      <tr>
        <td className="td">Senioren</td>
        <td className="td">Donnerstags</td>
        <td className="td">20:15 Uhr - 21:30 Uhr</td>
        <td className="td">Im Eichholz 7, 38448 Wolfsburg </td>
      </tr>
    </tbody>
  </table>
)
